// 18 style changes
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import 'assets/scss/theme.scss';

html,
body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  --mat-toolbar-title-text-font: 'Poppins', sans-serif;
}
body {
  margin: 0;
  background-color: var(--body-bg);
}

* {
  box-sizing: border-box;
}





// /* You can add global styles to this file, and also import other style files */

$color-negative-rating: #afa91b !default;
$color-ok-rating: #afa91b !default;
$color-positive-rating: #afa91b !default;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// $theme-colors: (
//   secondary: #e40659,
//   darkenblue: #455c6e
// );

// /* Import Bootstrap SCSS */
@import 'bootstrap/scss/bootstrap';

// /* Apply custom CSS variables for general styles */
:root {
  // --primary-orange: #fd6c21;
  // --primary-green: #606E36;
  // --primary-font: 'Poppins';
  // --primary-font-color: #3f4a3b;
  mat-card{
    padding: 10px;
  }
}

* {
  box-sizing: border-box;
  font-family: var(--primary-font);
  // color: var(--primary-font-color);
}

ngb-rating {
  font-size: 16px;
  color: var(--primary-orange);//#3fb91f;
  
}
.zero_margin{
  margin: 0 !important;
}
.zero_padding{
  padding: 0 !important;
}

.pd-25p{
  padding: 25px !important;
}

.star {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #d3d3d3;
}
.full {
  color: var(--primary-orange);//#3fb91f;
}
.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--primary-orange);// #3fb91f;
}

*:focus {
  outline: none !important;
}

.darkenblue {
  color: #455c6e;
}

body {
  overflow-x: hidden !important;
  font-family: "Poppins" !important;
  touch-action: pan-y;

}

body::-webkit-scrollbar {
  // width: 1em;
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
 // background-color:#3fb91f;// #e40659;
  //@error: 1px solid #3fb91f;// #e40659;
  background-color: #cccccc;
  outline: 1px solid #cccccc;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.divlogout {
  padding-bottom: 10px;
}

.btnLogout {
  background: #e40659;
  position: fixed;
  right: 19px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  width: 60px;
  height: 25px;
  color: #efefef;
  top: 20px;
  z-index: 555555;
  outline: none;
}

.btnLogout:hover {
  color: white;
  opacity: 0.7;
  // font-style: italic;
  cursor: pointer;
}

.headercss {
  height: 55px;
  background-color: white;
  text-align: center;
  font-size: 22px;
  font-family: monospace;
  border-bottom: 1px solid #ccc;
  // position: absolute;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 5;

}

.imgLog {
  width: 90px;
  position: absolute;
  top: 0px;
  left: 35px;
  height: 55px;
}
.imgLog1{
  width: 140px;
  position: absolute;
  top: 12px;
  left: 35px;
  height: auto;
}

.logoName {
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
  color: #0a4b6d;
  position: absolute;
  left: 0;
  top: 17px;
}

// .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
//     color: rgb(255, 255, 255) !important;
// }


.mat-button-toggle-group {
  height: 29px !important;
  margin: -1px -2px 0 -2px !important;
}
.mat-button-toggle-checked {
  background-color:var(--primary-orange)  !important;// #3fb91f !important;// var(--primary-orange)  !important;
  color: white !important;
  border: 1px solid var(--primary-orange)  !important;// #3fb91f !important;// var(--primary-orange)  !important;
  background-color: var(--primary-orange) !important;// #e40659 !important;
  color: white !important;
  border: 1px solid var(--primary-orange) !important;// #e40659 !important;

}

.mat-button-toggle {
  font-size: 12px !important;
  width: 98px !important;
  height: 28px !important;
  cursor: pointer !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 45px !important;
}

.mat-button-toggle-button:focus {
  outline: none !important;
  border: none !important;
}

.mat-button-toggle-appearance-standard {
  color: #3f4a3b !important;// #e40659 !important;
  background: #fff;
  font-weight: bold !important;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white !important;
  border: 1px solid var(--primary-orange)  !important;// #3fb91f !important;// #e40659 !important;
  border: 1px solid var(--primary-orange) !important;// #e40659 !important;
}

.mat-button-toggle-button {
  margin: -8px !important;
  cursor: pointer;
}

.mat-button-toggle-group-appearance-standard {
  border: solid 1px var(--primary-orange)  !important;// #3fb91f !important;// #e40659 !important;
  border: solid 1px var(--primary-orange) !important;// #e40659 !important;
}

.mat-button {
  border: none;
  background: var(--primary-orange)  !important;//#3fb91f !important;//  #e40659 !important;
  cursor: pointer !important;
  font-family: var(--primary-font);
  span{
    color: white !important;
  }
  &.no-hover{
    background: transparent !important;
    border: 1px solid var(--primary-orange) !important;
    span{
      color: var(--primary-orange) !important;
    }
  }
  &.action_btn {
    background: transparent !important;
    border: 1px solid var(--primary-orange) !important;
    span{
      color: var(--primary-orange) !important;
    }

    &:hover{
      background-color: var(--primary-orange) !important;
      border: none !important;

      span{
        color: #fff !important;
      }
    }

    &.f-f-btn {
      border-color: var(--primary-green) !important;
      &:hover {
        background-color: var(--primary-green) !important;
        border-color: #fff !important;
        span {
          color: #fff !important;
        }
      }
      span {
        color: var(--primary-green) !important;
      }
    }
  }
}

.mat-table{
  font-family: var(--primary-font) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color:var(--primary-orange)  !important;// #3fb91f !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color:var(--primary-orange)  !important;// #8bc34a !important;
  background-color: #3fb91f !important;
  background-color: #ff5722 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #8bc34a !important;
  background-color: #ff3d00 !important;
}

.mat-form-field {
  font-size: 13px !important;
}

.searchicon {
  color: var(--primary-orange);// #3fb90d;
  color: var(--primary-orange) !important;
  font-size: 18px;

}


.titlehead {
  margin-top: 4%;
  padding-left: 5%;
  margin-bottom: 0px;
  font-size: 18px;
  color:#3f4a3b !important;// #7030a0 !important;
}

.mat-dialog-container {
  border-radius: 0px !important;
}

.maintable {
  width: 100%;
  margin-top: 0%;
}

.bhead {
  padding-left: 5%;
}

.containecs {
  margin-left: 5%;
  margin-right: 4%;
}

.colcomn {
  padding: 20px;
  float: left;
  width: 25%;
  padding-left: 0px;
  padding-right: 14px;
  margin-right: 0px;
}

.card {
  height: 185px !important;
}

.card-header {
  padding: 7px !important;
  padding-left: 10px !important;
  margin-bottom: 0 !important;
	font-size: 0.8em !important;
  background-color: #7030a0 !important;
  color: white !important;
 
  border-bottom: 1px solid #fff !important;
  background-color: #fff !important;
  padding-top: 18px !important;
  
  color: #3f4a3b !important;
  font-weight: 600;
}

.tablecs {
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  color: gray;
}

.tablecs tr td {
  padding-bottom: 15px;
  word-break: break-word;
  color: #6b6b6b;
}

.mat-header-cell {
  // color: #697279 !important;
  // font-weight: bold;
  // font-size: 11px;
  color: #3F4A3B !important;
  font-weight: bold !important;
  word-break: auto-phrase;
  // font-size: 12px;
  padding-right: 15px;
}

.mat-cell {
 // color: #174a84;
 // font-size: 11px;
  color: #3F4A3B;
  font-size: 12px;
}

.das-pe {
  font-size: 11px;
  font-weight: bold;
  color: var(--primary-orange);
  cursor: pointer;
  padding-left: 10px;
  padding-right: 20px;
}
.das-pe1{
  font-size: 12px;
  font-weight: 500;
  color: var(--primary-orange);//#3fb91f;
  cursor: pointer;
  border-bottom-style: solid;
  border-bottom: 1.5px solid var(--primary-orange);// #3fb91f
 // color: #3fb91f;
  color: var(--primary-orange);
  cursor: pointer;
  border-bottom-style: solid;
  border-bottom: 1.5px solid var(--primary-orange);// #3fb91f;
  margin: 11px;
  position: relative;
  top: 15px;
}
.das-pe1:hover{
  color:black;
  border-bottom: 2px solid var(--primary-orange);// #3fb91f;
}
.divprintRt {
  // text-align: right;
  // padding-right: 50px;
  background: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.pDiv {
  margin-top: 0%;
  margin-left: 5%;
  margin-right: 5%;
  border: 1px solid #cccccc40;
}

.mat-paginator {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.recName {
  // font-weight: bold;
  // color: #174a84;
  // font-size: 14px;
  // padding-left: 10px;
  font-weight: bold;
  color: #3f4a3b;
  font-size: 14px;
  padding-left: 1px;
  position: relative;
  top: 15px;
}

.datecs {
  font-size: 12px;
  color: gray;
  padding-right: 10px;
  padding-left: 10px;
}

.createbtn {
  padding-left: 12px;
  padding-right: 10px;
  font-size: 13px;
}


.createbtn button {
  margin-top: 0px;
  height: 36px;
}

.searchSpan {
  padding-left: 10px;
}

.editpencil {
  font-size: 18px;
  cursor: pointer;
  color: var(--primary-orange);//#174a84;
  padding-right: 14px;
  padding-left: 8px;
}

.wdh {
  width: 80px;
}

input.mat-input-element {
  font-size: 12px !important;
}

.mat-paginator-container {
  color:#3F4A3B !important;// #174a84 !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: gray !important;
}
.mat-primary .mat-pseudo-checkbox-checked {
  background: var(--primary-orange) !important;// #3fb90d !important;
}
.mat-select-arrow {
  color: var(--primary-orange) !important;// #3fb90d !important;
  color:var(--primary-orange) !important;// #3fb90d !important;
}

.closeicon {
  font-size: 22px;
  cursor: pointer;
  position: relative;
  top: -11px;
}

.closeicondash {
  font-size: 30px;
  cursor: pointer;
  position: relative;
  top: -5px;
  color: #606060;
}

.hd5 {
  color:var(--primary-orange);// #3fb90d;
  color: var(--primary-orange) !important;// #3fb90d;
  font-weight: bold;
  margin-top: -20px;
  font-size: 15px;
}

.modifySaveClose {
  font-size: 9px;
  color: var(--primary-orange);// #3fb90d;
  font-weight: bold;
}

.page_layout{
  padding: 25px;
  background-color: #f6f6f6 !important;
  background: #f6f6f6 !important;
  min-height: 100vh;
}
.page_title_head{
  font-size: 18px;
  color: #3f4a3b !important;
  margin: 0px !important;
  padding: 0px !important;
}
.page_content{
  margin: 0px !important;
  padding: 0px !important;
}

@media screen and (min-width:1500px) {
  .mat-button-toggle-group {
    height: 30px !important;
  }
}


@media screen and (max-width:700px) {
  .colcomn {
    width: 100%;
  }
  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    height: unset !important;
}

.mat-option-text {
    word-break: break-word !important;
    line-height: 20px !important;
    white-space: normal !important;
}
}


//loader start

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
#cover-spin1 {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #F9F9F9;
  z-index: 9999;
}

@-webkit-keyframes spin1 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
#cover-spin1::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin1 .8s linear infinite;
  animation: spin1 .8s linear infinite;
}
.mat-ripple-element{
  background-color: none !important;
  background: none!important;
}
//loader end

.grid-display {
  display: grid !important;
}

.new_ol {
  bottom: -40px !important;
}

.new_ol li {
  background-color: #e40659 !important;
  height: 10px !important;
  width: 10px !important;
  border: 2px solid #dedede !important;
  padding: 0px !important;
  border-radius: 50%;
}

.new_ol li.active {
  transform: scale(1.2) !important;
}

#web_what_is_uearn #web_what_is_carousel {
  margin-top: 20px;
  font-family: "Muli";
}

#web_what_is_uearn #web_what_is_carousel .carousel-indicators {
  padding: 0 !important;
  margin: 0 !important;
  left: 25% !important;
  top: 5vw !important;
}

#web_what_is_uearn #web_what_is_carousel .carousel-indicators li {
  width: 3vw;
  height: 3vw;
  margin: 0;
  background-color: #F2F2F2;
  color: gray;
  font-size: 2vw;
  border-radius: 50%;
  text-indent: 0px !important;
  margin-left: 4vw;
  text-align: center;
  border: 1px solid #ccc;
}

#web_what_is_uearn #web_what_is_carousel .carousel-indicators li.active {
  background-color: #e40659;
  color: #fff;
}

#web_what_is_uearn #web_what_is_carousel .item_image {
  height: 25vw;
}

#web_what_is_uearn #web_what_is_carousel .item_text {
  margin-top: 10vw;
  font-family: "Muli";
  color: #000;
  padding-right: 14vw;
}

#web_what_is_uearn #web_what_is_carousel .item_text h3 {
  font-size: 2vw;
  font-weight: bold;
}

#web_what_is_uearn #web_what_is_carousel .item_text p {
  font-size: 1.3vw;
  line-height: 2.6vw;
}

#web_what_is_uearn #web_what_is_carousel .image1 {
  background: url('/assets/mob_images/ueaeb-ubfi333.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#web_what_is_uearn #web_what_is_carousel .image2 {
  background: url('/assets/mob_images/2-uear.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#web_what_is_uearn #web_what_is_carousel .image3 {
  background: url('/assets/mob_images/1-uear.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#web_what_is_uearn #web_what_is_carousel .image4 {
  background: url('/assets/mob_images/3-uear.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.review_text_video {
  font-weight: bold;
  font-size: 18px;
  font-style: initial;
  padding-left: 0px;
}

.customer_details_video {
  padding-left: 0;
  font-size: 17px;
  font-weight: bold;
}

.capitalize_text {
  text-transform: capitalize !important;
}

.statusCircle {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin: auto;
}

.text_wrap {
  white-space: pre-wrap;
}

//Training Styles start



body {
  background-color: #fff;
  overflow: auto;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #88888829 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline :hover {
  color: #888888 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0px 0px 10px 0 !important;
}

.focusArrow {
  outline: none !important;
  top: 0 !important
}

// .mat-sidenav {
//   background: #455c6e !important;
// }

.mat-list-base {
  padding: 10px 10px 15px 10px !important;
}

//Client select css start
.imgLOGO {
  width: 85px;
}

.img-client {
  width: 95px;
  border-radius: 12px;
  margin-left: 8%;
}

.client-flex-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 11% 0 11%;
}

.clientImg {
  width: 135px;
  height: 80px;
  border-radius: 15px;
}

.divClient {
  border: 1px solid #00000029;
  border-radius: 15px;
  margin: 0 0 5% 5%;
  cursor: pointer;
}

.TR-header {
  padding: 0 0 2% 15%;
  font-weight: bold;
  font-size: 25px
}

.TR-P-client-choose {
  text-align: right;
  padding-right: 5%;
}

.TR-P-client {
  text-align: center;
}

.TR-search-client {
  font-size: 20px;
}

.TR-search-icon {
  color: #9b9b9b;
  font-size: 22px;
}

.pdt {
  padding-top: 15px;
}

.ptop {
  padding-top: 28px;
}

th.mat-header-cell {
  text-align: left !important;
  color: #3f4a3b !important;
  font-weight: bold !important;
  font-size: 15px !important;
  font-family: 'poppins' !important;
  background: #eaeaea !important;
}

.search-icon {
  color: gray;
}

.scrollTable {
  max-height: 300px;
  overflow-y: scroll;
}

.scrollTable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.scrollTable::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.scrollTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #d6d5d5;
}

.mat-drawer-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.mat-drawer-content::-webkit-scrollbar {
  width: 10px !important;
  background-color: #F5F5F5;
}

.mat-drawer-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #d6d5d5;
}


.pointer{
  cursor: pointer;
}



.mat-elevation-z8 {
  width: 100% !important;
}

.linebelow {
  display: block;
  padding-top: 7px;
 // filter: invert(46%) sepia(53%) saturate(2198%) hue-rotate(63deg) brightness(91%) contrast(94%);
  filter: invert(46%) sepia(74%) saturate(3271%) hue-rotate(336deg) brightness(91%) contrast(106%);
}

.d-head {
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  // letter-spacing: 1.8px;
  letter-spacing: 0.5px;
  //color: #455c6e;
 // color: #3fb90d;

  color: #000000;
    font-weight: 500;

}

.detailsCss {
  color: #1dafa7;
  border-bottom: 1px solid var(--primary-orange);// #3fb90d;// #1dafa7;
  font-size: 14px;
  font-family: 'poppins';
  cursor: pointer;

  color: var(--primary-orange);// #3fb90d;
  border-bottom: 1px solid var(--primary-orange);//#3fb90d;
}

.editDetails {
  font-size: 13px;
  font-family: 'poppins';
  border-bottom: 1px solid;
  color: #128ebc;
  cursor: pointer;
}

.deleteDetails {
  font-size: 13px;
  font-family: 'poppins';
  border-bottom: 1px solid;
  color: #bc1233;
  margin-left: 15px;
  cursor: pointer;
}

.rightside {
  text-align: right;
}

.cancelbtn {
  font-family: 'poppins';
  font-weight: bold;
  color: #3fb90d !important;// #1dafa7 !important;
  border: solid 1px #3fb90d !important;// #1dafa7 !important;
  background-color: white !important;
  text-transform: uppercase;
  font-size: 13px;
  margin-right: 8px !important;
  margin-bottom: 10px !important;

  color: var(--primary-orange) !important;
  border: solid 1px var(--primary-orange) !important;
}

.submitbtn {
  font-family: 'poppins';
  font-weight: bold;
  color: white;
  border: solid 1px #1dafa7 !important;
  background-color: #1dafa7 !important;
  text-transform: uppercase;
  font-size: 13px;

  border: solid 1px var(--primary-orange) !important;
  background-color: var(--primary-orange) !important;
  
}

.mat-dialog-container {
  border-radius: 1px !important;
}

button:focus {
  outline: none !important;
}

.T-createbtn {
  font-family: "poppins";
  font-weight: bold;
  color: white;
  border: solid 1px #1dafa7 !important;
  background-color: #1dafa7 !important;
  text-transform: uppercase;
  font-size: 12px;
  width: auto !important;
  line-height: 32px !important;
  margin-left: 10px !important;
  top: 2px;

  border: solid 1px var(--primary-orange) !important;
  background-color: var(--primary-orange) !important;
}

.pad-LR {
  padding-left: 5%;
  padding-right: 5%;
}

.tgGroupClass.mat-button-toggle-group-appearance-standard {
  border: 1px solid var(--primary-orange) !important;// #1dafa7 !important;
}

.tgGroupClass.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: var(--primary-orange) !important;// #1dafa7 !important;
}

.tgClass.mat-button-toggle-appearance-standard {
  color: #1dafa7 !important;
  background-color: white;
  color :var(--primary-orange) !important;


}

.mat-button-toggle {
  font-weight: bold !important;
}

.tgClass.mat-button-toggle-checked {
  color: white !important;
  background-color:var(--primary-orange) !important;// #1dafa7 !important;
}

.mat-form-field {
  line-height: 1.5 !important;
}

.TA-adjust-Top {
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
}

.TA-ryt {
  text-align: right;
  margin-right: -3px;

}

#bloc1,
#bloc2,
#bloc3 {
  display: inherit;
  padding: 0px 3px 0px 5px;
  vertical-align: middle;
}

#bloc1 {
  color: #194f90;
  font-weight: bold;
  font-family: 'poppins';
  font-size: 14px;
  min-width: 29px;
}

.star-container :focus {
  outline: none !important;
}

.custom-color1 .mat-select-value {
  color: green !important;
}

.custom-color2 .mat-select-value {
  color: #3fb90d !important;
}

// .mat-menu-content:not(:empty) {
//   padding-top: 1px !important;
//   padding-bottom: 1px !important;
// }
// .mat-menu-item {
//   background: #455c6e !important;
//   color: white !important;
// }

.mat-drawer-inner-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  background-color: #F5F5F5 !important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  width: 8px !important;
  background-color: #F5F5F5 !important;
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;
  background-color: #d6d5d5 !important;
}

.CR {
  cursor: pointer;
}

.TA-outline:focus {
  outline: none !important;
}

.radioPresent.mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: #3fb90d !important;// #1dafa7 !important;
}

.radioAbsent.mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: red !important;
}

.searchAdjust {
  position: absolute;
  right: 6%;
  top: 0%;
}

.searchFix {
  position: absolute;
  right: 6%;
  top: 3%;
}

.profileTable {
  position: absolute;
  right: 0;
  text-align: right;
}

.profileTable table {
  width: 100%;
}


.popupoverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 999999999999;
}

.popupoverlay:target {
  visibility: visible;
  opacity: 1;
}

.delete-popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 2s ease-in-out;
}

.delete-popup .close {
  position: absolute;
  top: 0px;
  right: 10px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.delete-popup .content {
  max-height: 30%;
  overflow: auto;
  text-align: right;
}

.h3Message {
  font-size: 18px;
  color: gray;
  padding-top: 35px;
  padding-bottom: 25px;
  font-weight: bold;
}

.cancelDelete {
  border: 1px solid #ccc;
  border-radius: 2px;
  height: 32px;
  width: 100px;
  background: white;
  color: #000000a8;
  font-size: 15px;
  outline: none;
  text-transform: uppercase;
}

.delete-button {
  border: 1px solid var(--primary-orange);
  border-radius: 2px;
  height: 32px;
  width: 100px;
  background: var(--primary-orange);
  color: white;
  font-size: 15px;
  outline: none;
  margin-left: 10px;
  text-transform: uppercase;
}

.mat-calendar-table-header {
  color: var(--primary-orange) !important;// #1dafa7 !important;
}

.mat-calendar-body-selected {
  background-color: var(--primary-orange) !important;// #1dafa7 !important;
  color: #fff;
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--primary-orange) !important;//#1dafa7 !important;
}


.mat-focused .mat-form-field-underline .mat-form-field-ripple{

  background-color: gray !important;
}

.mat-focused .mat-form-field-label {
   color: gray !important;;
 }
// .mat-form-field-underline{

//   background-color: gray !important;
// }



.inputClr.mat-form-field-ripple {

  //background-color: #1dafa7 !important;
  background-color:var(--primary-orange) !important;// #3fb90d !important;
  background-color: var(--primary-orange) !important;
}

.inputClr.mat-focused.mat-form-field-label {
 // color: #1dafa7 !important; 
  color:var(--primary-orange) !important;// #3fb90d !important;
  color: var(--primary-orange) !important;
}

.inputClr.mat-form-field-appearance-legacy .mat-form-field-underline {
//  background-color: #1dafa7;
  background-color: var(--primary-orange) !important;// #3fb90d !important;
background-color: var(--primary-orange) !important;
}

.tgClass.mat-button-toggle-appearance-standard {
  border: 1px solid white !important;
}


// .matTabClass.mat-tab-label.mat-tab-label-active{
//   background-color: red !important;
//   opacity: 1 !important;
//   color:white !important
// } 


@media only screen and (max-width: 800px) {

  body {
    overflow: auto;
  }

  .TR-P-client {
    margin: 0 0 0 -15%;
  }

  .imgLOGO {
    width: 50px;
  }

  .img-client {
    width: 50px;
    border-radius: 12px;
    margin-left: 0%;
    padding-top: 4px;
  }

  .mbtm {
    margin-bottom: 5%;
  }

  .mat-cell {
    font-size: 10px !important;
  }

  th.mat-header-cell {
    font-size: 10px !important;
  }

  .clientImg {
    width: 115px !important;
    height: 75px !important;
  }

  .d-head {
    font-size: 12px !important;
  }

  .searchAdjust {
    position: relative;
    left: 0%;
    top: 0%;
  }

  .searchFix {
    position: relative;
    left: 2%;
  }

  .detailsCss {
    font-size: 10px;
  }

  .TR-imag {
    width: 15px !important;
    height: 15px !important;
    margin-right: 7% !important;
  }

  .mat-list-base {
    padding: 10px 2px 15px 2px !important;
  }

  .mat-list-item.active p {
    font-size: 10px !important;
  }

  .mat-list-base .mat-list-item .mat-line {
    font-size: 10px !important;
  }

  .mat-button-toggle {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 375px) {
  body {
    overflow: auto;
  }

  .TR-P-client {
    margin: 0 0 0 -50px;
  }

  .imgLOGO {
    width: 50px;
  }

  .img-client {
    width: 50px;
    border-radius: 12px;
    margin-left: 0%;
    padding-top: 4px;
  }

  .mbtm {
    margin-bottom: 5%;
  }

  .mat-cell {
    font-size: 10px !important;
  }

  th.mat-header-cell {
    font-size: 10px !important;
  }

  .clientImg {
    width: 110px !important;
    height: 70px !important;
  }

  .d-head {
    font-size: 12px !important;
  }

  .searchFix {
    position: relative;
    left: 2%;
  }

  .detailsCss {
    font-size: 10px;
  }

  .TR-imag {
    width: 15px;
    height: 15px;
    margin-right: 7%;
  }

  .mat-list-base {
    padding: 10px 2px 15px 2px !important;
  }

  .mat-list-base .mat-list-item .mat-line {
    font-size: 10px !important;
  }

  .mat-list-item.active p {
    font-size: 10px !important;
  }

  .mat-button-toggle {
    font-size: 11px !important;
  }
}

.ng-alert-dialog {
  position: absolute;
  top: 40px;
  z-index: 9;
  right: 0px;
  max-width: 320px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.mat-dialog-container {
  border-radius: 10px !important;
  padding: 15px !important;
}

.mat-card {
  font-family: "Poppins" !important
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 6px !important;
}



.paginator-training .mat-paginator-container {
  color: var(--primary-orange) !important;
 // color: #1dafa7 !important;
}

.mat-select-arrow {
  color: gray !important;
}



.tgMeetingToggle.mat-button-toggle-group-appearance-standard {
  border: 1px solid #1dafa7 !important;
  border-left: 0.4px solid #1dafa7 !important;
  border-right: 0.8px solid #1dafa7 !important;

  border: 1px solid var(--primary-orange) !important;
  border-left: 0.4px solid var(--primary-orange) !important;
  border-right: 0.8px solid var(--primary-orange) !important;
  
}

.mat-drawer-content {
  height: 100vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}


// client select css end
//Training Styles End
.auditorPage .mat-paginator-container {
  color: var(--primary-orange) !important;// #1dafa7 !important;
}

.mat-select-arrow {
  color: gray !important;
}


.mat-cell {
  word-break: break-all;
  padding-right: 15px;
}

.inside_container {
  width: 90%;
  margin: auto;
  padding: 40px 10px;
}

.inside_container_border {
  border: 1px solid #ccc;
}

//start call-flow
.call-Flow-input.mat-form-field-ripple {

  background-color: var(--primary-orange) !important;// #1dafa7 !important;
}

.call-Flow-input.mat-focused.mat-form-field-label {
  color: var(--primary-orange) !important;// #1dafa7 !important;
  ;
}

.call-Flow-input.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #ccc !important;
}

.h3-call {
  color: #455c71;
  font-size: 28px;
  font-family: 'poppins';
}

.savbtn {
  font-family: 'poppins';
  border: solid 1px var(--primary-orange) !important;// #1dafa7 !important;
  background-color: white !important;
  text-transform: uppercase;
  font-size: 15px;
  width: 115px;
  color: var(--primary-orange) !important;// #1dafa7 !important;
}

.wd-100 {
  width: 100%;
}

.iconOnBdrBtmDiv {
  text-align: center;
  position: relative;
  cursor: pointer;
  top: -34px;
}

.bdr-bg {
  background: #455c71;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 13px;
}

.mtCard {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.h3-heading {
  color: #7030a0;
  font-family: sans-serif;
  font-size: 26px;
  margin: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;

}

.h3-Form {
  color: #afabab;
  font-family: 'poppins';
  font-size: 18px;
  margin: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;
}

.callReq {
  color: #afabab;
  font-size: 19px;
  padding-right: 5px;
  cursor: pointer;
  padding-left: 4px;
}

.call-del {
  color: #afabab;
  font-size: 23px;
  position: relative;
  top: 10px;
  cursor: pointer;
}

.previewclass .mat-dialog-container {
  border-radius: 1px !important;
}

//end call-flow
.selectOutlineCss.mat-form-field-appearance-outline .mat-form-field-outline {
  //color: #3fb90d !important;
  color: var(--primary-orange) !important;
}

.textAlertMessage {
  color: red;
  font-size: 13px;
  font-family: 'poppins';
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}


//start css orchestration
.wd-100 {
  width: 100%;
}

.orchDDlOutline {
  line-height: 1.5 !important;
  font-size: 20px !important;
}

.m-div {
  border: 1.5px solid #d5d5d5;
        width: 100%;
        height: auto;
        border-radius: 8px;
}

.m-div p {
  position: relative;
  left: 20px;
  margin-top: -18px;
  height: 34px;
  font-size: 14px;
}

.m-div p .s-hd {
  color: white;
  display: inline-block;
  padding: 7px;
  padding-left: 15px;
  font-family: 'poppins';
  width: 200px;
  height: 34px;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #6f2fa3;
  font-size: 14px;
}

.lb1 {
  width: 200px;
  height: 23px;
  object-fit: contain;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  display: block;
  padding: 20px;
}

.radioAction {
  color: #707070;
  font-size: 21px !important;
  padding: 6% 0 2% 0;
}

.lb2 {
  color: #5f5858;
  margin-bottom: 0;
  position: relative;
  top: 20px;
  left: 19px;
  font-family: 'Poppins';
}

.saveSubmit {
  margin-top: 19px;
  border-radius: 5px;
  height: 45px;
  outline: none;
  border: none;
  color: white;
  background: var(--primary-orange);
  font-size: 22px;
  width: 175px;
}

  .saveSubmit{
      margin-top: 19px;
      border-radius: 5px;
      height: 45px;
      outline: none;
      border: none;
      color: white;
      background: var(--primary-orange);
      font-size: 22px;
      width: 175px;
}
 //end css orchestration

//  .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: #88888829 !important;
// }
// .mat-form-field-appearance-outline .mat-form-field-outline :hover{
//   color: #888888 !important;
// }
// .mat-form-field-appearance-outline .mat-form-field-infix {
//   padding: 0px 0px 10px 0 !important;
// }
// .mat-sidenav{
//   background: white !important;
// }
// // .mat-list-base {
// //     padding: 10px 10px 15px 10px !important;
// // }
.mlr-0{
  margin-right: 0;
  margin-left: 0;
}
.wd-100{
   width:100%;
}
.icon-search{
  font-size: 26px;
  color: gray;
  position: relative;
  top: 4px;
}
.h-icon{
  font-size: 40px;
  color: var(--primary-orange);
}
.scrollTable{
  max-height: 300px;
  overflow-y: scroll;
}
.scrollTable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.scrollTable::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.scrollTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #d6d5d5;
}

// .mat-drawer-content::-webkit-scrollbar-track {
// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// border-radius: 10px;
// background-color: #F5F5F5;
// }

// .mat-drawer-content::-webkit-scrollbar {
// width: 10px !important;
// background-color: #F5F5F5;
// }
// .mat-drawer-content::-webkit-scrollbar-thumb {
// border-radius: 10px;
// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
// background-color: #d6d5d5;
// }

// .mat-drawer-inner-container::-webkit-scrollbar-track {
// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
// border-radius: 10px !important;
// background-color: #F5F5F5 !important;
// }

// .mat-drawer-inner-container::-webkit-scrollbar {
// width: 8px !important;
// background-color: #F5F5F5 !important;
// }

// .mat-drawer-inner-container::-webkit-scrollbar-thumb {
// border-radius: 10px !important;
// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;
// background-color: #d6d5d5 !important;
// }

.CR{
cursor: pointer;
}
.L-head{
color: #6f2fa3;
font-weight: bold;
font-family: 'Poppins';
font-size: 15px;
}
.L2-hd{
color: #6c6c6c;
font-family: 'Poppins';
font-size: 11px;
font-weight: bold;
}
.Ldata{
color: #e10f59;
font-size: 11px;
font-family: 'Poppins';
font-weight: bold;
}

.mat-button:focus{
outline: none !important;
}
.gobtn{
border: 1px solid #ccc;
border-radius: 2px;
background: var(--primary-orange);
color: white;
font-size: 15px;
outline: none;
font-family: 'Poppins';
}

canvas{
position: absolute;
top: 15px;
left: 15px;
  z-index: 9;
}
.alert-snackbar-class.mat-snack-bar-container {
  color: white !important;
  background: var(--primary-orange) !important;
  padding: 24px 12px !important;
}


//Start Gignex
.color-one .mat-progress-bar-fill::after {
  background-color: #fd8246 !important;
}
.color-two .mat-progress-bar-fill::after {
  background-color: #7e8a5d !important;
}
.color-three .mat-progress-bar-fill::after {
  background-color: #52bede !important;
}
.color-four .mat-progress-bar-fill::after {
  background-color: #c3ba4a !important;
}
.color-five .mat-progress-bar-fill::after {
  background-color: #ff433b !important;
}
.mat-progress-bar-buffer {
  background: #dadada !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-orange) !important;//#3fb91f !important;
  background-color: var(--primary-orange) !important;
}
.gig-sidenav-content {
  // display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.gig-sidenav {
  height: 100vh;
  background-color: #f6f6f6 !important;
  background: #f6f6f6 !important;
  width: 241px !important;
  // transition: width 0.2s ease-in-out;

  &.minimize {
    width: 90px !important;
  }
}
//end Gignex
.v-mulSel .selected-list .c-btn {
  padding: 5px 6px;
  border: none !important;
  border-bottom: 1.9px solid var(--primary-orange) !important;
  border-radius: 0 !important;
  top: 5px !important;
  color: #928d9e !important;
}
.v-mulSel .selected-list .c-list .c-token {
  background: var(--primary-orange) !important;
  font-size: 0.8em !important;
  width: 200px !important;
  word-break: break-all !important;
}
.v-mulSel .pure-checkbox input[type="checkbox"] + label {
  font-size: 0.7em !important;
}
.v-mulSel .pure-checkbox input[type="checkbox"] + label:before {
  border: 1px solid var(--primary-orange) !important;
}
.v-mulSel .pure-checkbox input[type="checkbox"]:checked + label:before {
  background: var(--primary-orange) !important;
}

@media screen and (min-width:1500px) {
  .v-mulSel .selected-list .c-list .c-token {
    font-size: 1em !important;
    width: 250px !important;
  }
}
  @media screen and (max-width:1040px) {
    .v-mulSel .selected-list .c-list .c-token {
      width: 160px !important;
    }
}
@media screen and (max-width:900px) {
  .v-mulSel .selected-list .c-list .c-token {
    width: 130px !important;
  }
}


.reggennder .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primary-orange) !important;
}
.reggennder .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
 
  border-color: var(--primary-orange) !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color:var(--primary-orange) !important;// #3fb90d !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
 
	border-color: var(--primary-orange) !important;
    background-color: #f3f3f3 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
 
  border-color: var(--primary-orange) !important;
}
.v-mulSel .list-area {
  position: absolute !important;
  bottom: 0 !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--primary-orange) !important;// #3fb91f !important;
  background-color: var(--primary-orange) !important;
}
 .mat-pseudo-checkbox-checked, 
 .mat-pseudo-checkbox-indeterminate, 
 .mat-accent .mat-pseudo-checkbox-checked,
 .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--primary-orange) !important;// #3fb91f !important;
  background: var(--primary-orange) !important;
}
 .adjustWdRange.mat-select-panel {
  min-width: calc(100% + 20px) !important;
  position: relative !important;
  left: 30px !important;
}
.adjustWdRange .mat-select-panel {
  min-width: calc(100% + 20px) !important;
  position: relative !important;
  left: 30px !important;
}

 .mat-row:nth-child(even),.mat-row:nth-child(even) td {
  // background-color: #f2f2f2 !important;
  // background: #f2f2f2 !important;
  border-bottom-width: 0 !important;
}
      
 .mat-row:nth-child(odd){
  background-color: #ffffff !important;
  background: #ffffff !important;
  border-bottom-width: 0 !important;
}
.mat-row:nth-child(even) .mat-cell{
  background-color: #f2f2f2 !important;
  background: #f2f2f2 !important;
  border-bottom-width: 0 !important;
}
.mat-row:nth-child(odd) .mat-cell{
  background-color: #ffffff !important;
  background: #ffffff !important;
  border-bottom-width: 0 !important;
}
.vk-fntSW{
  font-weight: 500 !important;
  font-size: 1.2em !important;
}
.vk-fntWT,#vk-fntWT{
 font-weight: 500 !important;
}
// .fa-search{
//   -webkit-text-stroke: 0.4px white ;

// }

//start forgot password
.vk-forgotPwd{
  font-family: Poppins;
    font-size: 13px;
    text-align: right;
    color: #ffffff;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
}
.vk-mainForgotDiv{
  width: 420px;
  min-height: 400px;
  margin: 15px auto;
  padding: 10px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 5px 16px 0 #00000024;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}
.vk-fwText{
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #5a5757;
}
.vk-fwDesc{
  font-family: Poppins;
  font-size: 14px;
  color: #5a5757;
}
.vk-fwd-input{
  width: 270px;
  height: 40px;
  font-family: Poppins;
  font-size: 16px;
  color: #747373;
  text-indent: 10px;
  outline: none;
  border-radius: 4px;
  border: 1.5px solid #ccc;
}
.vk-fwBtn{
  width: 140px;
  height: 45px;
  margin: 8px;
  border-radius: 4px;
  background-color: #e00b37;
  color: #fff;
  font-family: 'Poppins';
  outline: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
.vk-fwBLog{
  font-family: Poppins;
    font-size: 15px;
    text-align: center;
    color: #e00b37;
}
.vk-fwBLog span{
  cursor: pointer;
}
.vk-fwBLog span:hover{
  text-decoration: underline;
}
.vk-ercs{
  padding: 4px 20px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #ffebef;
    color: #544c4c;
    font-family: 'Poppins';
}
.vk-clsErrorEmail{
  border: 1.5px solid red;
}
.vk-desc-reset{
  margin: 10px 25px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f5f5f5;  
  word-break: break-word;

}
.vk-reset-new-label{
  display: block;
  font-family: Poppins;
  font-size: 14px;
  text-align: left;
  color: #5a5757;
  position: relative;
  left: 17%;
}
@media  screen and (max-width:500px) {
  .vk-mainForgotDiv{
    width: 300px;
    min-height: 300px;
  }
  .vk-fwDesc{
    font-size: 13px;
  }
  .vk-fwd-input{
    width: 230px;
  }
}
@media  screen and (max-width:300px) {
  .vk-mainForgotDiv{
    width: 240px;
    min-height: 300px;
  }
  .vk-fwd-input{
    width: 200px;
  }
}
//end forgot password

.waveOverflow > wave{
  overflow: unset !important;
  }

  .waveOverflow > wave >wave{
    border-right: 2px solid #e93a3a !important;
    }

    .vk-waveTineline > timeline{
      display: initial !important;
      position: relative;
      bottom: 16px;
      user-select: none;
      height: 20px;
      width: 100%;
      overflow: hidden;
    }

    //start desktop calling

    .desktopcallingMainDiv{
      padding:5px 15px;
    }
    .btn-grp{
      display: flex;
      justify-content: flex-end;
      button{
        margin-right: 15px;
      }
    }
    //start comnex
    .CN-Main{
      padding: 25px;
      background: #f6f6f6;
    }
    .CN-Tab-head{
      font-size: 1.17em;
      font-weight: 500;
      font-family: 'Poppins';
      letter-spacing: 0.4px;
    }
    .CN-Tab-head1{
      font-size: 0.85em;
      font-weight: 500;
      font-family: 'Poppins';
      letter-spacing: 0.4px;
    }
    .dropdownCs{
      border-radius: 5px;
      line-height: 32px;
      text-indent: 8px; 
      border: 1px solid #ccc;
    }
    .arrowcs .mat-select-arrow {
      border-left: 6px solid transparent!important;
      border-right: 6px solid transparent!important;
      border-top: 10px solid!important;
      color: red!important;
      position: relative!important;
      top: 1px!important;
      right: 14px!important;
  }
  .CN-Card{
    margin: 15px 0px;
   
}
.chanel-text{
  font-family: Poppins;
  font-size: 0.94em;
  color: #acacac;
  padding-left: 12px;
}
.chanel-name{
  margin: 3.5px 10px 8px 0px;
  padding: 5px 15px 5px 15px;
  object-fit: contain;
  border-radius: 20px;
  border: solid 0.5px #a5a5a5;
  background-color: #ffffff;
  color: #a5a5a5;
  cursor: pointer;
  width: 180px;
  display: inline-block;
}
 //start popup msg
 .vk-popupmodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 99999999;
  
}
.vk-popupmodal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 24rem;
  border-radius: 0.5rem;
  align-items: center;
  display: inline-block;
  min-height: 130px;
  padding-top: 25px;
}
.vk-popclose-button {
  width: 26px;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  background-color: #fff;
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 27px;
  color: #000;
}
.vk-popclose-button:hover {
  background-color: var(--primary);
  opacity: 0.8;
}
.vk-popclose-ok-button{
  width: 26px;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  right: 4px;
  top:4px;
  font-size: 27px;
  color: #000;
}
.vk-show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1.0);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.vk-go-OK{
  margin: 20px 0 0 0;
  outline: none;
  border: none;
  color: white;
  font-size: 17px;
  height: 40px;
  border-radius: 2px;
  padding: 6px 0px;
  width: 150px;
  box-shadow: 0 3px 6px 0 #00000029;
  background-color: var(--primary);
}

.color-one .mat-progress-bar-fill::after {
  background-color: #fd8246 !important;
}
.color-two .mat-progress-bar-fill::after {
  background-color: #7e8a5d !important;
}
.color-three .mat-progress-bar-fill::after {
  background-color: #52bede !important;
}
.color-four .mat-progress-bar-fill::after {
  background-color: #c3ba4a !important;
}
.color-five .mat-progress-bar-fill::after {
  background-color: #ff433b !important;
}
.mat-progress-bar-buffer {
  background: #dadada !important;
}
 .colorShow1 .mat-progress-bar-fill::after {
  background-color: #39b54a !important;
}
.colorShow2 .mat-progress-bar-fill::after {
  background-color: #fcab29 !important;
}
 .colorShow3 .mat-progress-bar-fill::after {
  background-color: #f8333c !important;
}
.colorShow4 .mat-progress-bar-fill::after {
  background-color: #19a8dd !important;
}
 .colorShow5 .mat-progress-bar-fill::after {
  background-color: #9c60b2 !important;
}
.colorShow6 .mat-progress-bar-fill::after {
  background-color: #958080 !important;
}
 .colorShow7 .mat-progress-bar-fill::after {
  background-color: #1c779f !important;
}
.colorShow8 .mat-progress-bar-fill::after {
  background-color: #a7a110 !important;
}
.colorShow9 .mat-progress-bar-fill::after {
  background-color: #85a5b1 !important;
}
.colorShow10 .mat-progress-bar-fill::after {
  background-color: #d8af69 !important;
}




 //end popup msg
    @media screen and (max-width:700px) {
      .CN-Tab-head{
          font-size: 0.89em;
            padding-top: 50px;
        }
        .dropdownCs{
          margin:8px 0;
        }
        .chanel-name {
          margin: 3.5px 4px 8px 0px;
          padding: 5px 2px 5px 2px;
          width: 150px;
          font-size: 0.9em;
        }
  }
  @media screen and (max-width:400px) {
    .CN-Tab-head{
        font-size: 0.89em;
      }
      body {
        touch-action: pan-y;
      
      }
      
}
    //end cpmnex css

    //start for answercall sip
    .dc-popupoverlayAnswer {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000000b3;
      transition: opacity 500ms;
      z-index: 9;
    }
    
    .dc-popupoverlayAnswer:target {
     // visibility: visible;
      display:block;
      opacity: 1;
    }
    
    .dc-delete-popupAns {
      background: #fff;
      border-radius: 6px 6px 0 0;
      width: 280px;
      height: 400px;
      position: absolute;
      right: 2px;
      bottom: 2px;
      transition: all 2s ease-in-out;
    }
  .popupAns-hdr{
        height: 65px;
      border-bottom: 1px solid #ccc;
      text-align: center;
      width: 100%;
      font-size: 30px;
    font-weight:500;
      font-family: poppins;
      padding: 10px 0;
      background-color: #3fb90d;
      color: #fff;
      border-radius: 6px 6px 0 0;
  }
  .popupAns-ftr{
    // height: 50px;
     text-align: center;
    background:#fff;
     font-family: poppins;
  }
  .popupAns-ftr .ans-button{
    cursor:pointer;
    margin: 0 30px;
    border: 2px solid  #3fb90d;
    border-radius: 20px;
    width: 100px;
    height: 40px;
    background: #ffff;
    color:  #3fb90d;
    font-weight: 500;
    font-size: 10px;
   
  }
  .popupAns-ftr .reject-button{
   
    cursor:pointer;
    margin: 0 30px;
    border: 2px solid red;
    border-radius: 20px;
    width: 100px;
    height: 40px;
    background: #ffff;
    color: red;
    font-weight: 500;
    font-size: 10px;
  }
      
  .popupAns-content{
    font-family: poppins;
     font-size: 25px;
    text-align:center;
    height:250px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: 500;
    
  }
  @media screen and (min-width:1500px) {
    .dc-delete-popupAns {     
      width: 340px;
      height: 500px;
    }
    .popupAns-hdr{
      height: 70px;
    font-size: 34px;
    padding: 12px 0;
}
.popupAns-content {
  font-size: 32px;
  height: 320px;
}
.popupAns-ftr .ans-button{
  border-radius: 30px;
  width: 120px;
  height: 50px;
  font-size: 25px;
  margin: 0 15px;
}
.popupAns-ftr .reject-button{
  border-radius: 30px;
  width: 120px;
  height: 50px;
  font-size: 25px;
  margin: 0 15px;
}
      
}
    //end for answercall sip
    ::ng-deep .mat-calendar-body-selected {
      background-color: #ff5722 !important; 
      color: #fff !important; 
    }



    .mat-menu-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;max-height:calc(100vh - 48px);border-radius:4px;outline:0;min-height:64px}.mat-menu-panel.ng-animating{pointer-events:none}.cdk-high-contrast-active .mat-menu-panel{outline:solid 1px}.mat-menu-content:not(:empty){padding-top:8px;padding-bottom:8px}.mat-menu-item{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer;outline:none;border:none;-webkit-tap-highlight-color:transparent;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;position:relative}.mat-menu-item::-moz-focus-inner{border:0}.mat-menu-item[disabled]{cursor:default}[dir=rtl] .mat-menu-item{text-align:right}.mat-menu-item .mat-icon{margin-right:16px;vertical-align:middle}.mat-menu-item .mat-icon svg{vertical-align:top}[dir=rtl] .mat-menu-item .mat-icon{margin-left:16px;margin-right:0}.mat-menu-item[disabled]{pointer-events:none}.cdk-high-contrast-active .mat-menu-item.cdk-program-focused,.cdk-high-contrast-active .mat-menu-item.cdk-keyboard-focused,.cdk-high-contrast-active .mat-menu-item-highlighted{outline:dotted 1px}.mat-menu-item-submenu-trigger{padding-right:32px}.mat-menu-item-submenu-trigger::after{width:0;height:0;border-style:solid;border-width:5px 0 5px 5px;border-color:transparent transparent transparent currentColor;content:"";display:inline-block;position:absolute;top:50%;right:16px;transform:translateY(-50%)}[dir=rtl] .mat-menu-item-submenu-trigger{padding-right:16px;padding-left:32px}[dir=rtl] .mat-menu-item-submenu-trigger::after{right:auto;left:16px;transform:rotateY(180deg) translateY(-50%)}button.mat-menu-item{width:100%}.mat-menu-item .mat-menu-ripple{top:0;left:0;right:0;bottom:0;position:absolute;pointer-events:none}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



::-webkit-scrollbar {
	width: 4px;
	height: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 10px;
  }			
  
  ::-webkit-scrollbar-thumb:hover {
	background-color: #555;
  }
  
  ::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
  }

  .custom-table-container{
	max-height: 400px;
  min-height: 125px;
	border: 1px solid #ccc;
  // i want table row to have one color for one row and another color for another row

	border-radius: 5px;

  }

  .mat-mdc-row:nth-child(even) {
    background-color: #f5f5f5 !important;
  }
  .mat-mdc-row:nth-child(odd) {
    background-color: #fff !important;
  }

  // .mat-mdc-header-cell {
  //   // background-color: rgba(128, 128, 128, 0.5) !important; // less opacity  
  //   background-color: rgba(255, 87, 34, 0.8) !important; // less opacity

  //   color: #fff !important;
  // }
  
  .mat-mdc-raised-button {
    font-size: 12px !important;
  }

  @media (min-width: 1200px) {
    .mat-mdc-raised-button {
      font-size: 12px !important;
    }
  }

  @media (min-width: 900px) {
    .mat-mdc-raised-button {
      font-size: 12px !important;
    }
  }

  .mat-form-responsive {
    width: 200px;
  }

  @media (min-width: 1200px) {
    .mat-form-responsive {
      width: 160px;
    }
  }
  
  @media (min-width: 900px) {
    .mat-form-responsive {
      width: 180px;
    }
  }

  @media (min-width: 600px) {
    .mat-form-responsive {
      width: 170px;
    }
  }
  
  
  