@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

@import 'utilities/colors.scss';
// @import 'utilities/flex.scss';
@import 'utilities/dynamic.scss';
@import 'utilities/typography.scss';
// @import 'utilities/border.scss';
@import 'utilities/custom.scss';
// @import 'utilities/responsive.scss';
@import 'utilities/background.scss';
// @import 'utilities/override.scss';
@import 'bootstrap-utilities/bootstrap-grid.scss';
@import 'bootstrap-utilities/bootstrap-utilities.scss';

$_rest: (
  secondary: (
    0: #000000,
    10: #131c2b,
    20: #283041,
    25: #333c4d,
    30: #3e4759,
    35: #4a5365,
    40: #565e71,
    50: #6f778b,
    60: #8891a5,
    70: #a3abc0,
    80: #bec6dc,
    90: #dae2f9,
    95: #ecf0ff,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1a1b1f,
    20: #2f3033,
    25: #3b3b3f,
    30: #46464a,
    35: #525256,
    40: #5e5e62,
    50: #77777a,
    60: #919094,
    70: #ababaf,
    80: #c7c6ca,
    90: #e3e2e6,
    95: #f2f0f4,
    98: #faf9fd,
    99: #fdfbff,
    100: #ffffff,
    4: #0d0e11,
    6: #121316,
    12: #1f2022,
    17: #292a2c,
    22: #343537,
    24: #38393c,
    87: #dbd9dd,
    92: #e9e7eb,
    94: #efedf0,
    96: #f4f3f6,
  ),
  neutral-variant: (
    0: #000000,
    10: #181c22,
    20: #2d3038,
    25: #383b43,
    30: #44474e,
    35: #4f525a,
    40: #5b5e66,
    50: #74777f,
    60: #8e9099,
    70: #a9abb4,
    80: #c4c6d0,
    90: #e0e2ec,
    95: #eff0fa,
    98: #f9f9ff,
    99: #fdfbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffffff,
    90: #ffffff,
    95: #ffffff,
    98: #ffffff,
    99: #ffffff,
    100: #ffffff,
  ),
);

$_primary: map.merge($customPrimary, $_rest);
$_tertiary: map.merge($customAccent, $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
    use-system-variables: true,
  ),
  typography: (
    use-system-variables: true,
  ),
  density: (
    scale: 0,
  )
));


html {
  @include mat.core-theme($light-theme);
  @include mat.all-component-themes($light-theme);
  @include mat.system-level-colors($light-theme);
  @include mat.system-level-typography($light-theme);
}

// :root,
// :host {
//   --sys-background: #ffffff;
//   --sys-on-background: #22191a;
//   --sys-surface: #ffffff;
//   --sys-surface-dim: #e7d6d6;
//   --sys-surface-bright: #ffffff;
//   --sys-surface-container-lowest: #ffffff;
//   --sys-surface-container-low: #fff0f0;
//   --sys-surface-container: #ffffff;
//   --sys-surface-container-high: #f6e4e4;
//   --sys-surface-container-highest: #f0dede;
//   --sys-on-surface: #22191a;
//   --sys-surface-variant: #f4dddd;
//   --sys-on-surface-variant: #524343;
//   --sys-inverse-surface: #382e2e;
//   --sys-inverse-on-surface: #ffedec;
//   --sys-outline: #857373;
//   --sys-outline-variant: #d7c1c1;
//   --sys-shadow: #000000;
//   --sys-scrim: #000000;
//   --sys-surface-tint: #8f4a4c;
//   --sys-primary: #8f4a4c;
//   --sys-on-primary: #ffffff;
//   --sys-primary-container: #ffdad9;
//   --sys-on-primary-container: #733336;
//   --sys-inverse-primary: #ffb3b3;
//   --sys-secondary: #8f4a4c;
//   --sys-on-secondary: #ffffff;
//   --sys-secondary-container: #ffdad9;
//   --sys-on-secondary-container: #733335;
//   --sys-tertiary: #33618d;
//   --sys-on-tertiary: #ffffff;
//   --sys-tertiary-container: #d0e4ff;
//   --sys-on-tertiary-container: #144974;
//   --sys-error: #904a43;
//   --sys-on-error: #ffffff;
//   --sys-error-container: #ffdad5;
//   --sys-on-error-container: #73342d;
//   --sys-neutral: #827474;
//   --sys-neutral-variant: #857373;
//   --sys-primary-fixed: #ffdad9;
//   --sys-primary-fixed-dim: #ffb3b3;
//   --sys-on-primary-fixed: #3b080d;
//   --sys-on-primary-fixed-variant: #733336;
//   --sys-secondary-fixed: #ffdad9;
//   --sys-secondary-fixed-dim: #ffb3b3;
//   --sys-on-secondary-fixed: #3b080d;
//   --sys-on-secondary-fixed-variant: #733335;
//   --sys-tertiary-fixed: #d0e4ff;
//   --sys-tertiary-fixed-dim: #9ecafc;
//   --sys-on-tertiary-fixed: #001d34;
//   --sys-on-tertiary-fixed-variant: #144974;
//   --sys-inverse-secondary: #ffb3b3;
//   --sys-inverse-tertiary: #9ecafc;
//   --sys-inverse-error: #ffb4ab;
//   --sys-error-fixed: #ffdad5;
//   --sys-error-fixed-dim: #ffb4ab;
//   --sys-on-error-fixed: #3b0907;
//   --sys-on-error-fixed-variant: #73342d;
// }
