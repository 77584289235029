@for $i from 0 through 100 {
  // Width classes
  .w-#{$i*4}-px {
    width: $i * 4 + px !important;
    min-width: $i * 4 + px !important;
  }

  // Hight classes
  .h-#{$i*4}-px {
    height: $i * 4 + px !important;
    min-height: $i * 4 + px !important;
  }

  // Padding classes
  .p-#{$i*4}-px {
    padding: $i * 4 + px !important;
  }

  .pt-#{$i*4}-px {
    padding-top: $i * 4 + px !important;
  }

  .pb-#{$i*4}-px {
    padding-bottom: $i * 4 + px !important;
  }

  .ps-#{$i*4}-px {
    padding-left: $i * 4 + px !important;
  }

  .pe-#{$i*4}-px {
    padding-right: $i * 4 + px !important;
  }

  // Margin classes
  .m-#{$i*4}-px {
    margin: $i * 4 + px !important;
  }

  .mt-#{$i*4}-px {
    margin-top: $i * 4 + px !important;
  }

  .mb-#{$i*4}-px {
    margin-bottom: $i * 4 + px !important;
  }

  .ms-#{$i*4}-px {
    margin-left: $i * 4 + px !important;
  }

  .me-#{$i*4}-px {
    margin-right: $i * 4 + px !important;
  }

  // Gap classes
  .gap-#{$i*4}-px {
    gap: $i * 4 + px !important;
  }
}

.w-500-px {
  width: 500px !important;
  min-width: 500px !important;
}
