$black: #000000;
$white: #ffffff;

$customPrimary: (
  0: #000000,
  10: #40000a,
  20: #c7061a,
  25: #cf0b27,
  30: #d40e2e,
  35: #d91136,
  40: #fd6c21,
  // 40: #dd133c,
  50: #e23659,
  60: #e75a77,
  70: #ee899e,
  80: #f5b8c5,
  90: #ffdad9,
  95: #ffedec,
  98: #fff8f7,
  99: #fffbff,
  100: #ffffff,
);

// $customPrimary: (
//   0: #fd6c21,
//   10: #fd6c21,
//   20: #fd6c21,
//   25: #fd6c21,
//   30: #fd6c21,
//   35: #fd6c21,
//   40: #fd6c21,
//   50: #fd6c21,
//   60: #fd6c21,
//   70: #fd6c21,
//   80: #fd6c21,
//   90: #fd6c21,
//   95: #fd6c21,
//   98: #fd6c21,
//   99: #fd6c21,
//   100: #fd6c21,
// );

$customAccent: (
  0: #000000,
  10: #001d34,
  20: #0d6eec,
  25: #1680ef,
  30: #1b8af1,
  35: #2094f3,
  40: #249cf4,
  50: #45abf6,
  60: #66baf7,
  70: #92cefa,
  80: #bde1fc,
  90: #e5f3fe,
  95: #e9f1ff,
  98: #f8f9ff,
  99: #fcfcff,
  100: #ffffff,
);

$customPurple: (
  900: #7c2fac,
  800: #BA68C8,
  700: #E1BEE7,
  600: #cc82da,
  500: #cd87da,
  400: #ce9ed6,
  300: #dba6e4,
  200: #ead4ee,
  100: #f2e9f3,
  50: #f8f8f8,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  ),
);

$customWarn: (
  50: #fce4ec,
  100: #f8bcd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e96982,
  600: #e61a5b,
  700: #e31651,
  800: #df1247,
  900: #d90a35,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  ),
);

:root {
  --primary: #{map-get($customPrimary, 40)};
  --accent: #{map-get($customAccent, 40)};
  --purple: #{map-get($customPurple, 500)};
  --warn: #{map-get($customWarn, 500)};

  --body-bg: #E0E0E0;

  --header-height: 60px;
  
  .custom-accent-bg-900 {
    background-color: map-get($customPurple, 900);
  }
  .custom-accent-bg-800 {
    background-color: map-get($customPurple, 800);
  }
  .custom-accent-bg-700 {
    background-color: map-get($customPurple, 700);
  }
  .custom-accent-bg-600 {
    background-color: map-get($customPurple, 600);
  }
  .custom-accent-bg-500 {
    background-color: map-get($customPurple, 500);
  }
  .custom-accent-bg-400 {
    background-color: map-get($customPurple, 400);
  }
  .custom-accent-bg-300 {
    background-color: map-get($customPurple, 300);
  }
  .custom-accent-bg-200 {
    background-color: map-get($customPurple, 200);
  }
  .custom-accent-bg-100 {
    background-color: map-get($customPurple, 100);
  }
  .custom-accent-bg-50 {
    background-color: map-get($customPurple, 50);
  }
  
  // text
  .custom-accent-text-900 {
    color: map-get($customPurple, 900);
  }
  .custom-accent-text-800 {
    color: map-get($customPurple, 800);
  }
  .custom-accent-text-700 {
    color: map-get($customPurple, 700);
  }
  .custom-accent-text-600 {
    color: map-get($customPurple, 600);
  }
  .custom-accent-text-500 {
    color: map-get($customPurple, 500);
  }
  .custom-accent-text-400 {
    color: map-get($customPurple, 400);
  }
  .custom-accent-text-300 {
    color: map-get($customPurple, 300);
  }
  .custom-accent-text-200 {
    color: map-get($customPurple, 200);
  }
  .custom-accent-text-100 {
    color: map-get($customPurple, 100);
  }
  .custom-accent-text-50 {
    color: map-get($customPurple, 50);
  }
}
