@for $i from 3 through 12 {
  .f-#{$i*4}-px {
    font-size: $i * 4 + px !important;
    line-height: $i * 4 + px !important;
  }

  .f-#{$i*4} {
    @if $i < 4 {
      font-size: max(12px, min(1.2vw, #{$i * 4 + px})) !important;
      line-height: max(12px, min(1.2vw, #{$i * 4 + px})) !important;
    } @else if $i < 8 {
      font-size: max(16px, min(1.6vw, #{$i * 4 + px})) !important;
      line-height: max(16px, min(1.6vw, #{$i * 4 + px})) !important;
    } @else {
      font-size: max(18px, min(2.2vw, #{$i * 4 + px})) !important;
      line-height: max(18px, min(2.2vw, #{$i * 4 + px})) !important;
    }
  }
}

.f-60-px {
  font-size: 60px !important;
  line-height: 60px !important;
}

.link-accent {
  color: var(--accent) !important;
}

.link-black {
  color: black !important;
}